.task-list-container{
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
}
.task-underline{
    width:90%;
    margin-top:2vh;
    height: 1px;
    border: 0;
    background: white;
}

.task_list{
    width:100%;
}