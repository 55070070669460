p{
    color:white;
}

.skill-reticle{
    width:95%;
    color:white;
    --aug-border-all:1px;
}

.skill-item{
    margin-top:3px;
    width:95%;
    margin-left:2.5%;
}