.skills-list{
    text-align:center;
    align-items:center;
    overflow-y:auto;
    height:51vh;
    margin-left:1vw;
}

h1{
    color:white;
}

.skills{
    text-align:center;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    width:10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }