.column-container{
    height:calc(100vh - 60px);
    width:100vw;
    display:flex;
    align-items: center;
}

.tasks-column{
    height:100%;
    width:84vw;
}

.stats-skills-ach-columm{
    height:100%;
    width:15vw;
}

.line-3 {
    margin-left:10%;
    width:80%;
    margin-top:2vh;
    height: 1px;
    border: 0;
    background: white;
  }

.v1 {
    top:50%;
    transform:translate(-50%);
    border-left: 1px solid white;
    height: 95%;
    margin-left: 10px;
    top: 0;
  }